// States
import states from './settings-text-modifier.state.js'

// Components
import SliderModifier from '../slider-modifier/Slider-modifier.vue'
import MdiInformationOutline from 'vue-material-design-icons/InformationOutline.vue'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'

// Services
import { getDefaultStyles } from '@/services/dnd-style-engine/dnd-style-engine.service'
import { mjmlJson, readingDirection } from '@/services/states/states.service'
import { ckTranslatorMjText } from '@/services/utils/utils.service'

// Library
import {
  cloneDeep as _cloneDeep,
  get as _get
} from 'lodash-es'
import i18n from '@/plugins/i18n'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-text'

// Properties
const props = {
  conf: Object,
  mjml: Object
}

const components = {
  SliderModifier,
  custColorPicker,
  MdiInformationOutline
}

// Vue@data
const data = function () {
  return {
    // If we have to keep data sync (for deep edit), use states passed from open modal method
    // here, it's coming from settings-accordion-modifier 
    states: _get(this.conf, 'keepSync.customStates') 
      ? this.conf.keepSync.components[name].states
      : _cloneDeep(states)
  }
}
// Vue@data

// Methods
const methods = {


  /**
   * Generate quote for composed font to avoid
   * MJML issue as "Arial Black" becoming "Arial #000"
   * @param {String} fonts (Font list as "Arial Black, Arial-Black, Arial")
   */
  quoteComposedFontName (fonts) {
    return fonts.split(',').map(f => {
      const isComposedName = f.trim().split(' ').length > 1
      return isComposedName ? `'${f.trim()}'` : f.trim()
    }).join()
  },

  // Func@revertReadingDirection
  /**
   * Enable or disable RTL option
   * @param  {Boolean} isRtl (active or not)
   */
  revertReadingDirection (isRtl) {
    this.states.loading = true
    this.states.revertReadingDirection = isRtl
    this.states.editorConfig.language.content = isRtl ? 'ar' : this.locale
    if (_get(this.mjml.attributes, 'css-class')) this.mjml.attributes['css-class'] = isRtl ? 'is-rtl' : ''
    // eslint-disable-next-line no-prototype-builtins
    if (_get(this.conf, 'parent', {}).hasOwnProperty('css-class')) this.conf.parent['css-class'] = isRtl ? 'is-rtl' : ''
    // eslint-disable-next-line no-prototype-builtins
    if (_get(this.conf, 'parent', {}).hasOwnProperty('icon-position')) this.conf.parent['icon-position'] = isRtl ? 'left' : 'right'
    if (!this.conf.disableAlign && this.mjml.attributes.align !== 'center') this.mjml.attributes.align = isRtl ? 'right' : 'left'

    this.mjml.content = ckTranslatorMjText({
      entry: this.content,
      dest: 'MJML',
      options: { 
        isRtl, 
        onSwitch: true,
        isEnforcedLTR: readingDirection().get() === 'rtl'
      }
    })

    this.$nextTick(() => {
      this.states.loading = false
      this.updateEditorStyle()
    })
  },
  // Func@revertReadingDirection

  // Func@updateEditorStyle
  /**
   * Update background color of ckEditor content
   * depending layers of colors
   */
  updateEditorStyle () {
    const bgColorLayers = mjmlJson().get({ context: 'bg-color-layers', id: this.mjml.id })
    const bgColorPriority = bgColorLayers.shift()
    const textColor = this.mjml.attributes.color
    const fontSize = this.mjml.attributes['font-size']
    const fontFamily = this.mjml.attributes['font-family']
    setTimeout(() => {
      if (!this.$refs.CKEditor) return
      const ckEditorContent = this.$refs.CKEditor
        .querySelector('.ck-editor__main')

      ckEditorContent.style['background-color'] = _get(bgColorPriority, 'bgColor', 'transparant')
      ckEditorContent.style.color = textColor
      ckEditorContent.style['font-size'] = fontSize
      ckEditorContent.style['font-family'] = fontFamily
    }, 300)
  },
  // Func@updateEditorStyle

  updateFontColor (val) {
    this.mjml.attributes.color = val
    this.updateEditorStyle()
  },

  onEditorReady( editor ) {
    // Get a list of all toolbar items and reposition native ckeditor plugins tooltips if needed
    const toolbarItemNameList = Array.from( editor.ui.componentFactory.names() ).filter(item => item !== "selectAll" && item !== "undo" && item !== "redo" && !item.includes('alignment:'))
    const southWestList = ['PersonalizationFields', 'InsertNbsp']
    const southList = ['bulletedList']
    const ignoreList = [
      'heading',
      'bold',
      'italic',
      'underline',
      'link',
      'PersonalizationFields'
    ]
    editor.ui.view.toolbar.items.filter(item => !item.element.className.includes('ck-toolbar__separator')).map((item, index) => {
      if (southWestList.includes(toolbarItemNameList[index])) {
        item.tooltipPosition = 'sw'
      }
      if (southList.includes(toolbarItemNameList[index])) {
        item.tooltipPosition = 's'
      }
      if (!ignoreList.includes(toolbarItemNameList[index])) {
        item.class = 'ck-dnd-button'
      }
      return item
    })
  }
}

const computed = {
  // Func@isEnforcedLTR
  /**
   * Check if text is enforced left to right (in RTL default context)
   * @returns {Boolean}
   */
  isEnforcedLTR () {
    return this.mjml.content && this.mjml.content.includes('dir="ltr"')
  },
  // Func@isEnforcedLTR

  // Func@isRTL
  /**
   * Check reading direction
   * @return {Boolean}
   */
  isRTL () {
    if (!this.mjml.content) return readingDirection().get() === 'rtl'
    return !this.isEnforcedLTR && (this.mjml.content.includes('dir="rtl"') || readingDirection().get() === 'rtl')
  },
  // Func@isRTL

  // Func@fonts
  /**
   * Font list for default conf
   * @return {Array} (ckeditor fonts - default option)
   */
  fonts () {
    const fonts = _cloneDeep(states.editorConfig.fontFamily.options)
    fonts.shift() // remove default entry

    return fonts
  },
  // Func@fonts

  // Func@getLinksStyles
  /**
   * Get links default style
   * @return {String} (Links CSS properties)
   */
  getLinksStyles () {
    return getDefaultStyles().links()
  },
  // Func@getLinksStyles

  // Func@content
  /**
   * Content text without language format option
   * (managed directly via CKEDITOR config file)
   * @get {String} (Current text html)
   * @set (String) (Updated text html)
   */
  content: {
    // getter
    get () {
      return ckTranslatorMjText({
        entry: this.mjml.content,
        dest: 'CKE',
        options: {
          isRtl: this.states.revertReadingDirection,
          onSwitch: this.states.loading,
          isEnforcedLTR: readingDirection().get() === 'rtl'
        }
      })
    },
    // setter
    set (html) {
      this.mjml.content = ckTranslatorMjText({
        entry: html,
        dest: 'MJML',
        options: { 
          isRtl: this.states.revertReadingDirection,
          onSwitch: this.states.loading,
          isEnforcedLTR: readingDirection().get() === 'rtl'
        }
      })
    }
  },
  // Func@content

  // Func@fontSize
  /**
   * Font size modifier
   */
  fontSize: {
    // getter
    get () {
      return parseInt(this.mjml.attributes['font-size'], 10)
    },
    // setter
    set (newValue) {
      this.$set(this.mjml.attributes, 'font-size', `${newValue}px`)
      this.updateEditorStyle()
    }
  },
  // Func@fontSize

  // Func@bgColor
  /**
   * Current bg colors
   * @return {String} (BG color of current mj-text)
   */
  bgColor: {
    // getter
    get () {
      const customKey = _get(this.conf, 'key.bgColor')
      const key = customKey || 'background-color'
      return this.mjml.attributes[key]
    },
    // setter
    set (newValue) {
      const customKey = _get(this.conf, 'key.bgColor')
      const key = customKey || 'background-color'
      this.mjml.attributes[key] = newValue
    }
  },
  // Func@bgColor

  // Func@fontFamily
  /**
   * As seen on issue https://gitlab.numberly.in/team-crm-automation/qa/dnd-editor/-/issues/954
   * Firefox doesn't escape fonts with numbers in it. Thus in db we need to rename them from 
   * Avenir LT Std 35 Light to Avenir-LT-Std-35-Light.
   * Then to display them in the correct format we need to remove the '-' from the name. 
   * 
   * Current font family
   * @return {String} current font family without '-'
   */
  fontFamily(){
    return this.mjml.attributes['font-family'].replaceAll('-',' ')
  },
  // Func@fontFamily
  locale() {
    return i18n.locale
  }
}

// Vue@watchTemplate
const watch = {
  'states.revertReadingDirection': {
    handler (boolean) {
      if (_get(this.conf, 'keepSync.emitter')) return
      this.revertReadingDirection(boolean)
    }
  },
  bgColor: {
    handler () {
      this.updateEditorStyle()
    }
  }
}
// Vue@watchTemplate

// Func@created
/**
 * Set rtl options on create
 */
function mounted () {
  if (this.isRTL) {
    setTimeout(() => {
      this.revertReadingDirection(true)
    })

    return
  }
  this.updateEditorStyle()
}
// Func@created

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  mounted,
  computed,
  components
}
